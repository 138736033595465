#logo {
  width: 150px;
}
#banners {
  .btn-investir {
    bottom: 10%;
    right: 15%;
    position: absolute;
    background: rgba(0,0,0,.2);
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 12px 64px;
    font-size: 1.5rem;
    color: #fff;
    transition: all ease .3s;
    &:hover {
      background-color: rgba(0,0,0,.8);
      transition: all ease .3s;
    }
  }
  .slide-container{
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    height: 100vh;
    margin-top: -80px;
    display: flex;
    align-items: center;
  }  
} 

.slide-container .caption {
  z-index: 9;
  position: absolute;
  width: 100%;
  right: 15%;
  left: 15%;
  color: #fff;
  &::before {
    content: "";
    width: 100%;
    height: 1px;
    background: #fff;
    display: block;
    position: absolute;
    right: 0;
  }

  &::after {
    content: "";
    width: calc(100%  - 15%);
    height: 1px;
    background: #fff;
    display: block;
    position: absolute;
    left: -15%;
  }

  h1 {
    font-size: 4rem;
  }

  p {font-size: 24px;}

  .caption-content {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
  }

}
.ml-2 {
  margin-left: 1rem;
} 

#aplicacoes {
  background: url(../src/assets/images/bg-investimento.png);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  position: relative;
  min-height: 500px;
  padding-bottom: 80px;

  h1  {
    color: #012d54;
  }

  .card-row {
    min-height: 360px;
  }

  .col-md {
    box-shadow: 2px 2px 10px #333;
    background-color: #2a5581;
    padding: 50px 35px;
    position: relative;
    color: #fff;
    margin-right: -20px;

    &::before {     
        content: "";
        background: url(../src/assets/images/background.png) no-repeat;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-position: 50%;
        background-size: cover;
        opacity: .9;
    }  
  }

  .col-md:first-child:after{
    content: "";
    position: absolute;
    left: 65%;
    top: -140px;
    background: url(../src/assets/images/arrow1.png) no-repeat;
    display: block;
    width: 278px;
    height: 233px
  }
  .col-md:last-child:after{
      content: "";
      position: absolute;
      background: url(../src/assets/images/arrow2.png) no-repeat;
      left: -57%;
      bottom: -195px;
      display: block;
      width: 278px;
      height: 233px
  }
}


#beneficios {
  background: url(../src/assets/images/background.png) no-repeat;
  background-position: bottom;
  background-size: cover;
  width: 100%;
  padding: 100px 0;
  border: 2px solid #e2e2e2;
  border-left: none;
  border-right: none;
  position: relative;

  .benefits__item--solucoes{
    background: url(../src/assets/images/beneficios-solucoes.png) no-repeat;
    background-position: 50%;
    background-size: cover;
    width: 35vw;
    height: 400px;
    position: relative;
    box-shadow: 2px 2px 10px #999;
  }

  .benefits__item--diferenciais{
    background: url(../src/assets/images/beneficios-diferenciais.png) no-repeat;
    background-position: 50%;
    background-size: cover;
    width: 35vw;
    height: 400px;
    position: relative;
    box-shadow: 2px 2px 10px #999;
  }

  .benefits__text{
    color: #012d54;
    background-color: #fff;
    box-shadow: 2px 2px 10px #adadad;
    width: 450px;
    margin: 0;
    padding: 24px;
    position: absolute;
  }
  .benefits__text h3{ 
      font-weight: 400;
      margin-bottom: 20px
  }
  .benefits__text p{    
      text-align: justify;
  }
  .benefits__text--solucoes{
      top: 60%;
      left: 90%
  }
  .benefits__text--diferenciais{
      top: 60%;
      right: 90%
  }
}

footer {
  background: #4d86bf;
  color: #fff;
}

footer button {
  background: transparent;
  border: 1px solid #eee;
  padding: 10px 30px;
  color: #fff;
  border-radius: 4px;
}

footer a {
  margin-right: 10px;
}

.investor-relationship p {
  margin-bottom: 0.125rem;
}
.address {
  background: linear-gradient(80deg,#012d54,transparent);
}

.address p:first-child {
  text-transform: uppercase;
  padding-top: 1rem;
}

.hover-slide {
  background: linear-gradient(80deg,#012d54,transparent);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.channels-button {
  text-decoration: none;
  color: white;
}

.channels-button:hover {
  text-decoration: underline;
  color: inherit;
}


@media only screen and (max-width: 600px) {
  #segmentos .item {
    margin-bottom: 20px;
  }
  .carousel-control-prev, .carousel-control-next {
    display: none !important;
  }

  .slide-container .caption.caption-left {
    padding: 20px !important;
  }

  .slide-container .caption.caption-right {
    padding: 20px !important;
  }

  .slide-container .caption.caption-left h1 {
    font-size: 35px;    
  }

  .slide-container .caption.caption-left p {
    font-size: 18px;
  }
  .bg-left {
    background-position: right !important;
  }
}

@media (max-width:1024px){
  .investment h2{
      text-align: center;
      font-size: 1.4em;
      padding: 42px 0 0;
  }
  .investment .investment__img{
      height: 300px;
  }
}
@media (max-width: 768px){
  .investment .investment-steps{
      padding-bottom: 24px;
  }

  #beneficios .benefits__item--solucoes {
    width: 100%;    
  }

  #beneficios .benefits__text--solucoes {
    top: 100%;
    left: 0;
    right: 0;
  }

  #beneficios .benefits__text--diferenciais {
    top: 100%;
    right: 0;
    width: 100%;
  }

  footer {
    margin-top: 150px;
  }

  #beneficios .benefits__item--diferenciais {
    width: 100%;
    margin-top: 300px;
  }

  .slide-container .caption {
    left: 0 !important;
    right: 0 !important;
    padding: 0 15px;
    h1 {
      font-size: 22px;
    }
  }
  #banners {
    .btn-investir {
      width: 70%;
    }
  }
  #aplicacoes {
    text-align: center;
    img {
      width: 70%;
    }
    .col-md{
        margin: 40px 16px;
    }
    .col-md:first-child:after,.investment .investment-steps .col-md:last-child:after{
        left: 10%;
        top: 85%;
        transform: rotate(90deg);
        zoom: .6;
        z-index: 100;
    }
    .col-md:last-child:after{
        transform: rotate(90deg);
        top: -30%;
        left: 0;
        zoom: .7;
    }
  }
}
