.text-pink {
    color: #d88f81 !important;
}

.btn-blue {
    background: #4d86bf;
    opacity: 1;
    padding: 1em 2em 1em 2em;
    box-shadow: none;
    border: 0px solid RGBA(255,255,255,1);
    border-radius: 8px;
    color: #fff;
}

.text-bold {
    font-weight: bold;
}

@media (min-width: 1400px) {
    .container {
        max-width: 1140px !important;
    }
}